// import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'js/components';

import style from './Idle.module.scss';
import logo from '../../../images/samsung.svg';

export const Idle = () => {
  const navigate = useNavigate();

  const { gameId } = useParams();
  // const content = useSelector((models) => models.appModel.content.pages.idle);

  const startGame = () => {
    navigate('/' + gameId + '/play');
  };

  return (
    <div className={style.wrap}>
      <div className={style.brandWrap}>
        <div className={style.brand} style={{ backgroundImage: 'url(' + logo + ')' }}></div>
      </div>
      <div className={style.prefix}>Brings you</div>
      <div className={style.title}>Spin to Win!</div>
      <Button onClick={startGame}>Start</Button>
    </div>
  );
};

export default Idle;
