import { useSelector } from 'react-redux';
import style from './Disclaimer.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

export const Disclaimer = () => {
  const { gameId } = useParams();
  const content = useSelector((models) => models.appModel.content.disclaimer);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const goToFinal = () => {
    console.log('going final');
    navigate('/final');
  };
  const goToHome = () => {
    console.log('going home');

    navigate(-1);
  };

  // Define animation variants
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <div onClick={pathname.endsWith('/final') ? goToHome : goToFinal} className={style.wrap}>
      <AnimatePresence>
        {pathname.endsWith('/final') ? (
          <>
            <motion.div
              key="logo"
              className={style.logo}
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5 }}
            ></motion.div>
            <motion.div
              key="final"
              className={style.cross}
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={variants}
              transition={{ duration: 0.5 }}
            >
              <img src="/dev/touchpoint/images/cross.svg" alt="Age limit icon" />
            </motion.div>
            <motion.div
              key="logo"
              className={style.chevron}
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5 }}
            ></motion.div>
          </>
        ) : (
          <>
            <motion.div
              key="logo"
              className={style.logo}
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5 }}
            >
              <img src="/dev/touchpoint/images/kervelogo.svg" alt="Age limit icon" />
            </motion.div>
            <motion.div
              key="inner"
              className={style.inner}
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5 }}
            >
              <div>{content.text}</div>
            </motion.div>
            <motion.div
              key="chevron"
              className={style.chevron}
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5 }}
            >
              <img src="/dev/touchpoint/images/chcvron.svg" alt="Age limit icon" />
            </motion.div>
            <motion.div
              key="text"
              className={style.text}
              initial="hidden"
              animate="visible"
              variants={variants}
              transition={{ duration: 0.5 }}
            >
              <p>Powered by</p>
              <h3>KERVE CREATIVE</h3>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default Disclaimer;
