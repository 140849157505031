// import { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
// import { AnimatePresence, motion } from 'framer-motion';

import style from './TextLose.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../Button/Button';

export const TextLose = () => {
  const navigate = useNavigate();
  const content = useSelector((models) => models.appModel.content.pages.lose);
  const { gameId } = useParams();

  const playAgain = () => {
    navigate('/' + gameId);
  };
  return (
    <div className={style.wrap}>
      <div className={style.title}>{content.title}</div>
      <div className={style.body}>{content.body}</div>
      <Button onClick={playAgain}>Spin Again</Button>
    </div>
  );
};

export default TextLose;
