import { useEffect } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';

import store from 'js/store/store';
import { Idle, Wrapper } from 'js/components';
import { Error, Home, Lose, Play, Result, Win, Final, Form } from 'js/pages';

const App = () => {
  const navigate = useNavigate();
  const gameId = 'ise';

  // init
  useEffect(() => {
    // navigate to landing page

    // fetch content and data etc
    store.dispatch.appModel.init({
      gameId,
      navigate,
    });
    navigate('/form');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <Routes>
        <Route path="/" exact={true} element={<Error />} />
        <Route path="/:gameId" exact={true} element={<Idle />} />

        <Route path="/:gameId" exact={true} element={<Home />} />
        <Route path="/:gameId/play" exact={true} element={<Play />} />
        <Route path="/:gameId/result" exact={true} element={<Result />} />
        <Route path="/:gameId/lose" exact={true} element={<Lose />} />
        <Route path="/:gameId/win" exact={true} element={<Win />} />
        <Route path="/final" exact={true} element={<Final />} />
        <Route path="/form" exact={true} element={<Form />} />
      </Routes>
    </Wrapper>
  );
};

export default App;
