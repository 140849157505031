import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { gsap } from 'gsap';
import { Draggable } from 'gsap/dist/Draggable';
import clsx from 'clsx';

import InertiaPlugin from 'js/libs/InertiaPlugin.min.js';

import { Canvas, CurvedText } from 'js/components';
import { animateTextIn, animateTextOut } from 'js/components/TextPlay/TextPlay';
import { useWindowSize } from 'js/hooks';
import style from './Spinner.module.scss';

import product from './product1.png';
import product2 from './product2.png';
import product3 from './product3.png';

if (typeof window !== 'undefined') {
  gsap.registerPlugin(Draggable, InertiaPlugin);
}

// options

const devDisableRotation = false;
const devCenterSpinner = false;
const devSkipIntro = false;

const fullRotationTime = 6;
const spinRotationTime = 2.5;

const minVelocity = 250;

// state

let winner;
let numSectors;

let tlRef1;
let tlRef2;
let imageRef1;
let imageRef2;
let imageRef3;
let innerRef;
let instructionsRef;
let spinnerRef;
let wrapperRef;
let draggableRef;
let dragRef;

let enableSwiping;
let disableSwiping;
let saveResult;

let isRotating = false;

// get spinner dimensions

const getDimensions = (width, wrapperHeight) => {
  const fullMultiplier = 1.4;
  const fullSize = Math.round((width > wrapperHeight ? width : wrapperHeight) * fullMultiplier);
  const fullLeft = Math.round((fullSize - width) / 2);
  const fullTop = Math.round((fullSize - wrapperHeight) / 2);

  const initialMultiplier = 0.81;
  const initialMaxSize = 640;
  const initialIdealSize = Math.round(width * 1.7);
  const initialSize = initialIdealSize > initialMaxSize ? initialMaxSize : initialIdealSize;
  const initialScale = initialSize / fullSize;
  const initialDist = initialSize * initialMultiplier + (wrapperHeight - initialSize) / 2;

  const readyMultiplier = 0.71;
  const readyScale = initialScale;
  const readyDist = initialSize * readyMultiplier + (wrapperHeight - initialSize) / 2;

  const resultScale = 2.5;
  const resultDist = readyDist;

  const instructionsSize = fullSize * readyScale;
  const instructionsLeft = Math.round((instructionsSize - width) / 2);
  const instructionsTop = Math.round((instructionsSize * 0.75 - readyDist - wrapperHeight) / 2);

  return {
    fullSize,
    fullLeft,
    fullTop,
    initialScale,
    initialDist,
    readyScale,
    readyDist,
    resultScale,
    resultDist,
    instructionsSize,
    instructionsLeft,
    instructionsTop,
  };
};

// apply spinner transform (i.e. position it correctly on screen)

let transformState = null;
const doTransform = (dims, duration, state) => {
  const newState = state ? state : transformState;
  if (dims) {
    if (devCenterSpinner) {
      gsap.set(innerRef.current, {
        y: 0,
        scale: 0.4,
      });
    } else {
      switch (newState) {
        case 'transformInitial':
          gsap.to(innerRef.current, {
            y: dims.initialDist,
            scale: dims.initialScale,
            duration: duration,
          });
          break;
        case 'transformFull':
          gsap.to(innerRef.current, {
            y: 0,
            scale: 1,
            duration: duration,
          });
          break;
        case 'transformReady':
          gsap.to(innerRef.current, {
            y: dims.readyDist,
            scale: dims.readyScale,
            duration: duration,
          });
          break;
        case 'transformResult':
          gsap.to(innerRef.current, {
            y: dims.resultDist,
            scale: dims.resultScale,
            duration: duration,
            ease: 'Power2.easeInOut',
          });
          break;
        case 'transformReset':
          gsap.to(innerRef.current, {
            y: dims.initialDist,
            scale: dims.initialScale,
            duration: duration,
          });

          break;
        case 'hideSpinner':
          gsap.to(innerRef.current, {
            y: dims.initialDist,
            scale: 0,
            duration: duration,
          });
          break;
        default:
          break;
      }
    }
    gsap.set(instructionsRef.current, {
      y: dims.readyDist,
      scale: dims.readyScale,
    });
  }
  transformState = newState;
};

// spinner state - rotate

const doRotate = () => {
  console.log('doRotate');
  if (!devDisableRotation && !isRotating) {
    // kill existing timeline
    try {
      if (tlRef1.current) {
        tlRef1.current.kill();
      }
    } catch (e) {
      console.log('doRotate kill error', e);
    }

    // new repeating timeline
    tlRef1.current = gsap.timeline({
      repeat: -1,
    });

    // rotate the spinner
    tlRef1.current.fromTo(
      spinnerRef.current,
      {
        rotation: 0,
      },
      {
        rotation: 360,
        duration: fullRotationTime,
        ease: 'none',
      },
      0
    );

    isRotating = true;
  }
};

// spinner state - play animation

const doPlay = (dims) => {
  console.log('doPlay');
  if (devSkipIntro) {
    endRotate();
    doTransform(dims, 0, 'transformReady');
  } else {
    // kill existing timeline
    try {
      if (tlRef2.current) {
        tlRef2.current.kill();
      }
    } catch (e) {
      console.log('doPlay kill error', e);
    }

    // new timeline
    tlRef2.current = gsap.timeline();

    // move spinner to fill screen
    let delay = 0;
    tlRef2.current.add(function () {
      doTransform(dims, 1, 'transformFull');
    }, delay);

    // show first product
    delay += 0.5;
    tlRef2.current.fromTo(
      imageRef1.current,
      {
        yPercent: 50,
        opacity: 0,
        scale: 0.5,
      },
      {
        yPercent: 0,
        opacity: 1,
        scale: 1,
        duration: 0.5,
      },
      delay
    );

    // hide first product
    delay += 2;
    tlRef2.current.fromTo(
      imageRef1.current,
      {
        yPercent: 0,
        opacity: 1,
        scale: 1,
      },
      {
        yPercent: 0,
        opacity: 0,
        scale: 1,
        duration: 0.5,
        immediateRender: false,
      },
      delay
    );

    // show second product
    delay += 0.4;
    tlRef2.current.fromTo(
      imageRef2.current,
      {
        yPercent: 0,
        opacity: 0,
        scale: 1,
      },
      {
        yPercent: 0,
        opacity: 1,
        scale: 1,
        duration: 0.5,
      },
      delay
    );

    // hide second product
    delay += 2;
    tlRef2.current.fromTo(
      imageRef2.current,
      {
        yPercent: 0,
        opacity: 1,
        scale: 1,
      },
      {
        yPercent: 0,
        opacity: 0,
        scale: 1,
        duration: 0.5,
        immediateRender: false,
      },
      delay
    );

    // show third product
    delay += 0.4;
    tlRef2.current.fromTo(
      imageRef3.current,
      {
        yPercent: 0,
        opacity: 0,
        scale: 1,
      },
      {
        yPercent: 0,
        opacity: 1,
        scale: 1,
        duration: 0.5,
      },
      delay
    );

    // hide third product
    delay += 2;
    tlRef2.current.fromTo(
      imageRef3.current,
      {
        yPercent: 0,
        opacity: 1,
        scale: 1,
      },
      {
        yPercent: 50,
        opacity: 0,
        scale: 0.8,
        duration: 0.5,
        immediateRender: false,
        ease: 'Back.easeIn',
      },
      delay
    );

    //  move spinner to play position
    delay += 0.3;
    tlRef2.current.add(function () {
      doTransform(dims, 1, 'transformReady');
    }, delay);

    // end spinner rotation
    tlRef2.current.add(endRotate, delay);
  }
};

// spinner state - result

const doResult = (dims) => {
  console.log('doResult');
  doTransform(dims, 1, 'transformResult');
};

// spinner state - end rotation, animate to start position

const endRotate = () => {
  console.log('endRotate');
  // kill existing timeline
  try {
    if (tlRef1.current) {
      isRotating = false;
      tlRef1.current.pause();
      tlRef1.current.kill();
    }
  } catch (e) {
    console.log('endRotate error', e);
  }

  // options
  const currentProgress = tlRef1.current.progress();
  const remainingProgress = 1 - currentProgress;
  const remainingTime = remainingProgress * fullRotationTime;
  const spinDuration = (fullRotationTime + remainingTime) * 0.25;

  // animate spinner to a stop
  tlRef1.current = gsap.timeline();
  tlRef1.current.to(
    spinnerRef.current,
    {
      rotation: 360 * 2,
      duration: spinDuration,
      ease: 'Power1.easeOut',
    },
    0
  );

  // show instructions
  tlRef1.current.fromTo(
    instructionsRef.current,
    {
      opacity: 0,
    },
    {
      opacity: 1,
      duration: 0.5,
    },
    spinDuration
  );

  // animate in text
  tlRef1.current.add(animateTextIn, spinDuration);

  // enable swiping
  tlRef1.current.add(enableSwiping, spinDuration);
};

// drag effects

const onDrag = (e) => {
  const curRotation = gsap.getProperty(draggableRef.current, 'rotation');
  gsap.set(spinnerRef.current, {
    rotation: curRotation,
  });
};

const onDragEnd = (target) => {
  const velocity = InertiaPlugin.getVelocity(target, 'rotation');
  if (velocity >= minVelocity) {
    endSwipe();
    spinToResult('clockwise', velocity);
  } else if (velocity <= -minVelocity) {
    endSwipe();
    spinToResult('anticlockwise', velocity);
  }
};

// spinner state - end swipe capability

const endSwipe = () => {
  // disable draggable
  Draggable.get(draggableRef.current).kill();
  disableSwiping();

  // hide text
  // animateTextOut();

  // hide instructions
  gsap.to(instructionsRef.current, {
    opacity: 0,
    duration: 0.5,
  });
};

// spinner state - spin to result

const spinToResult = (direction, velocity) => {
  console.log('spinToResult', winner);

  // win - spin to first sector
  if (winner) {
    const randomSector = 0;
    const randomAngle = 0;
    const randomTime = 0;
    gsap.to(spinnerRef.current, {
      rotation: (randomAngle + 360 * 3) * (direction === 'clockwise' ? 1 : -1),
      duration: randomTime + spinRotationTime,
      ease: 'Power2.easeOut',
    });
    gsap.delayedCall(randomTime + spinRotationTime, showResult, [randomSector]);
  }
  // lose - spin to a random other sector (not the first)
  else {
    const sectorAngle = 360 / numSectors;
    let randomSector = Math.round(Math.random() * (numSectors - 1));
    // make sure we don't land on sector 0
    if (randomSector < 1) {
      randomSector = 1;
    }
    const randomAngle = randomSector * sectorAngle;
    const randomTime = spinRotationTime * (randomSector / numSectors);
    gsap.to(spinnerRef.current, {
      rotation: (randomAngle + 360 * 3) * (direction === 'clockwise' ? 1 : -1),
      duration: randomTime + spinRotationTime,
      ease: 'Power2.easeOut',
    });
    gsap.delayedCall(randomTime + spinRotationTime, showResult, [randomSector]);
  }
};

// spinner state - expand and show result

const showResult = (sector) => {
  console.log('showResult');
  // console.log('showResult', sector);
  saveResult(sector);
  animateTextOut();
};

// spinner state - reset

const doReset = (dims) => {
  console.log('doReset');
  doTransform(dims, 1, 'transformReset');
};

const dohide = (dims) => {
  doTransform(dims, 1, 'hideSpinner');
};
// render component

export const Spinner = () => {
  winner = useSelector((models) => models.appModel.winner);
  const content = useSelector((models) => models.appModel.content);
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { width, height } = useWindowSize();

  const [isSwipeable, setIsSwipeable] = useState(false);
  const [hasWinStatus, setHasWinStatus] = useState(false);
  const [spinState, setSpinState] = useState(null);
  const [dims, setDims] = useState(null);

  numSectors = content.flavours.length;

  tlRef1 = useRef();
  tlRef2 = useRef();
  imageRef1 = useRef();
  imageRef2 = useRef();
  imageRef3 = useRef();
  innerRef = useRef();
  instructionsRef = useRef();
  spinnerRef = useRef();
  wrapperRef = useRef();
  draggableRef = useRef();
  dragRef = useRef();

  // initialise
  useEffect(() => {
    doTransform(dims, 1, 'transformInitial');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle viewport resizing
  useEffect(() => {
    const wrapperHeight = wrapperRef.current.clientHeight;
    setDims(getDimensions(width, wrapperHeight));
  }, [width, height, pathname]);
  useEffect(() => {
    doTransform(dims, 0);
  }, [dims]);

  // handle route changes
  useEffect(() => {
    if (pathname.endsWith('/play')) {
      setSpinState('spinPlay');
    } else if (pathname.endsWith('/result') || pathname.endsWith('/win') || pathname.endsWith('/lose')) {
      setSpinState('spinResult');
    } else if (pathname.endsWith('/final') || pathname.endsWith('/form')) {
      setSpinState('spinHide');
    } else if (!spinState) {
      setSpinState('spinRotate');
    } else {
      setSpinState('spinReset');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // handle spin state changes
  useEffect(() => {
    if (spinState) {
      console.log('%c--- spinState - ' + spinState + ' ---', 'color:#0F8491');
      switch (spinState) {
        case 'spinRotate':
          doRotate();
          break;
        case 'spinPlay':
          doRotate();
          doPlay(dims);
          break;
        case 'spinResult':
          doResult(dims);
          break;
        case 'spinReset':
          dispatch.appModel.resetGame();
          doReset(dims);
          setTimeout(function () {
            setSpinState('spinRotate');
          }, 1200);
          break;
        case 'spinHide':
          dohide(dims);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [spinState]);

  // handle swiping
  useEffect(() => {
    if (isSwipeable && hasWinStatus) {
      // kill existing draggable (if applicable)
      if (dragRef.current) {
        try {
          dragRef.current[0].kill();
        } catch (e) {
          console.log(e);
        }
      }

      // create new draggable
      dragRef.current = Draggable.create(draggableRef.current, {
        type: 'rotation',
        onDrag: onDrag,
        onDragEnd: function () {
          onDragEnd(draggableRef.current);
        },
        onThrowUpdate: onDrag,
        throwProps: true,
        inertia: true,
        snap: (endValue) => {
          return 0;
        },
      });
    }
  }, [isSwipeable, hasWinStatus]);

  // handle prize status
  useEffect(() => {
    if (winner !== null) {
      setHasWinStatus(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [winner]);

  // enable swiping
  enableSwiping = () => {
    setIsSwipeable(true);
  };
  disableSwiping = () => {
    setIsSwipeable(false);
  };

  // save result
  saveResult = (sector) => {
    dispatch.appModel.setState({ sectorResult: sector });
  };

  // render
  return (
    <div className={clsx(style.wrap, { [style.wrapActive]: isSwipeable && hasWinStatus })} ref={wrapperRef}>
      <div className={style.outer}>
        <div
          ref={instructionsRef}
          className={style.instructions}
          style={
            dims
              ? {
                  // display: 'block',
                  width: dims.fullSize,
                  height: dims.fullSize,
                  left: -dims.fullLeft,
                  top: -dims.fullTop,
                }
              : null
          }
        >
          <CurvedText />
        </div>
        <div ref={innerRef} className={clsx(style.inner)}>
          <div
            ref={spinnerRef}
            className={style.spinner}
            style={
              dims
                ? {
                    width: dims.fullSize,
                    height: dims.fullSize,
                    left: -dims.fullLeft,
                    top: -dims.fullTop,
                  }
                : null
            }
          >
            <Canvas />
          </div>
          {isSwipeable && hasWinStatus && (
            <div
              ref={draggableRef}
              className={style.draggable}
              style={
                dims
                  ? {
                      width: dims.fullSize,
                      height: dims.fullSize,
                      left: -dims.fullLeft,
                      top: -dims.fullTop,
                    }
                  : null
              }
            ></div>
          )}
        </div>
        <div className={style.productWrap}>
          <div ref={imageRef1} className={style.productImage} style={{ backgroundImage: 'url(' + product + ')' }}></div>
        </div>
        <div className={style.productWrap}>
          <div
            ref={imageRef2}
            className={style.productImage}
            style={{ backgroundImage: 'url(' + product2 + ')' }}
          ></div>
        </div>
        <div className={style.productWrap}>
          <div
            ref={imageRef3}
            className={style.productImage}
            style={{ backgroundImage: 'url(' + product3 + ')' }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Spinner;
