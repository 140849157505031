import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Button } from 'js/components';
import style from './form.module.scss';
import logo from '../../../images/samsung.svg';
import axios from 'axios';

export const Form = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [erroMessager, setErrorMessage] = useState('Sorry there was a problem. Please try again.');

  const { gameId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    // const data = [
    //   'company': data.company,
    // ]

    const formdata = new FormData();
    formdata.append('first_name', data.first_name);
    formdata.append('last_name', data.last_name);
    formdata.append('company', data.company);
    formdata.append('email', data.email);
    formdata.append('use_vxt_in_business', data.use_vxt_in_business ? 'yes' : 'no');
    formdata.append('what_do_you_require', data.what_do_you_require);
    formdata.append('opt_in_1', data.opt_in_1 ? 'yes' : 'no');
    formdata.append('opt_in_2', data.opt_in_2 ? 'yes' : 'no');

    console.log(formdata);
    axios
      .post('https://api-spin2win.kerve.com/api/v1/saveEntry', formdata)
      .then((response) => {
        // Handle form submission here (e.g., send data to a server)

        console.log(response);
        if (response.data.success === true) {
          console.log(response);
          console.log(data);
          console.log(gameId);
          navigate('/' + gameId);
        } else {
          setErrorMessage(response.data.response);
          setError(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setError(true);
      });
  };

  return (
    <div className={style.wrap}>
      <div className={style.brandWrap}>
        <div className={style.brand} style={{ backgroundImage: 'url(' + logo + ')' }}></div>
      </div>
      <p className={style.intro}>Please complete our survey and participate in our interactive game</p>
      {error && <p className={style.error}>{erroMessager}</p>}
      <form onSubmit={handleSubmit(onSubmit)}>
        <label>First Name:</label>
        <input
          type="text"
          {...register('first_name', {
            required: 'FIrst name is required',
          })}
        />
        {errors.first_name && <p>{errors.first_name.message}</p>}

        <label>Last Name:</label>
        <input
          type="text"
          {...register('last_name', {
            required: 'Last name is required',
          })}
        />
        {errors.last_name && <p>{errors.last_name.message}</p>}

        <label>Email:</label>
        <input
          type="text"
          {...register('email', {
            required: 'Email is required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Invalid email address',
            },
          })}
        />
        {errors.email && <p>{errors.email.message}</p>}

        <label>Company:</label>
        <input
          type="text"
          {...register('company', {
            required: 'Company is required',
          })}
        />
        {errors.company && <p>{errors.company.message}</p>}

        <label className="radio">
          I will be using or reselling VXT in my business
          <input type="checkbox" {...register('use_vxt_in_business')} />
        </label>

        <label>What do you require from a CMS (VXT)?</label>
        <textarea {...register('what_do_you_require')} />

        <label className="radio">
          Opt-in for collection of basic information via initial web form
          <input type="checkbox" {...register('opt_in_1')} />
        </label>
        <p className={style.disclaimer}>
          By completing this form, you agree that Samsung will process your personal data (name, email address, company)
          to complete our survey and participate in our interactive game. The Samsung Privacy Policy{' '}
          <a href="https://www.samsung.com/uk/business/info/privacy">
            (https://www.samsung.com/uk/business/info/privacy/)
          </a>{' '}
          will apply to our use of your personal data
        </p>

        <label className="radio">
          Opt-in for receiving post-seminar marketing and promotional communications from Samsung and its subsidiaries
          <input type="checkbox" {...register('opt_in_2')} />
        </label>
        <p className={style.disclaimer}>
          Want to keep in touch? Please check this box if you would like to receive future marketing communications from
          Samsung and its subsidiaries by email. Samsung will share your contact information with its subsidiaries for
          the purposes of sending you marketing communications and promotions. The Samsung Privacy Policy
          <a href="https://www.samsung.com/uk/business/info/privacy">
            (https://www.samsung.com/uk/business/info/privacy/)
          </a>{' '}
          will apply to our use of your personal data
        </p>

        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default Form;
