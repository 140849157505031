// import { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
// import { AnimatePresence, motion } from 'framer-motion';

import style from './TextResult.module.scss';

export const TextResult = () => {
  const flavours = useSelector((models) => models.appModel.content.flavours);
  const sectorResult = useSelector((models) => models.appModel.sectorResult);

  const resultName = sectorResult || sectorResult === 0 ? flavours[sectorResult].name : 'XXXXXX';

  return (
    <div className={style.wrap}>
      <div className={style.title}>{resultName}</div>
    </div>
  );
};

export default TextResult;
