import style from './final.module.scss';
import logo from '../../../images/kerve.svg';

export const Final = () => {
  return (
    <div className={style.wrap}>
      <div className={style.brandWrap}>
        <div className={style.brand} style={{ backgroundImage: 'url(' + logo + ')' }}></div>
        <div className={style.prefix}>
          <p>If you’re at ISE 2024 and interested in creative interactive experiences,</p>
          <p>let’s talk.</p>
        </div>
        <div className={style.contact}>
          <h3>Contact</h3>
          <p>Dominic Bradbury</p>
          <p>
            on <a href="tel:+447966 037295">+44 (0)7966 037295</a>
          </p>
          <a href="mailto:dom@kerve.co.uk">dom@kerve.co.uk</a>
        </div>
        <div className={style.contact}>
          <h3>Or Visit</h3>
          <a href="https://kerve.co.uk">kerve.co.uk</a>
        </div>
      </div>
    </div>
  );
};

export default Final;
