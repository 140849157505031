import { useSelector } from 'react-redux';

import style from './CurvedText.module.scss';

export const CurvedText = () => {
  const content = useSelector((models) => models.appModel.content.pages.play);

  const leftArrow = '<tspan class="icon-arrow-left">&#xe900;&nbsp;&nbsp;</tspan>';
  const rightArrow = '<tspan class="icon-arrow-left">&nbsp;&nbsp;&#xe901;</tspan>';
  const textPath =
    '<textPath xlink:href="#curve" text-anchor="middle" startOffset="50%">' +
    leftArrow +
    content.swipe +
    rightArrow +
    '</textPath>';

  return (
    <div className={style.wrap}>
      <div className={style.outer}>
        <div className={style.inner}>
          <svg className={style.svg} viewBox="0 0 500 500">
            <path id="curve" d="M0,375c0-138.071,111.929-250,250-250s250,111.929,250,250" fill="transparent" />
            <text width="500" fill="#000" dangerouslySetInnerHTML={{ __html: textPath }}></text>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default CurvedText;
