// import { useState } from 'react';
import { useSelector } from 'react-redux';
// import { useRouter } from 'next/router';
// import { AnimatePresence, motion } from 'framer-motion';

import style from './TextWin.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../Button/Button';

export const TextWin = () => {
  // const content = useSelector((models) => models.appModel.content.pages.win);
  // const prizeCode = useSelector((models) => models.appModel.prizeCode);
  const prizeName = useSelector((models) => models.appModel.prizeName);
  const { gameId } = useParams();
  const navigate = useNavigate();

  const playAgain = () => {
    navigate('/' + gameId);
  };

  return (
    <div className={style.wrap}>
      <div className={style.prefix}>Congratulations</div>
      <div className={style.title}>
        You've
        <br />
        won
      </div>
      <div className={style.prize}>{prizeName}</div>
      <div className={style.collect}>
        Please show this screen at the Samsung desk to claim your prize (while stocks last).
      </div>
      <Button onClick={playAgain}>Spin Again</Button>
      <div className={style.disclaimer}>By spinning again any unclaimed prizes will be lost.</div>
    </div>
  );
};

export default TextWin;
